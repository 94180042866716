<template>
  <b-form-group>
    <CInput type="text"
            size="lg"
            list="customer-search-list"
            name="search_text"
            v-model="search_text"
            autocomplete="off"
            debounce="500"
            @change="selectCustomer"
            @input="searchCustomers"
            @keydown.enter="selectCustomer"
            placeholder="Fahrschüler suchen">
      <template v-slot:append>
        <b-button variant="link" size="sm" @click="selectCustomer" class="input-group-text text-decoration-none pb-3">
          <i class="cil-magnifying-glass"></i>
        </b-button>
      </template>
    </CInput>
    <b-form-datalist id="customer-search-list" :options="search_result" value-field="name" text-field="name"></b-form-datalist>
  </b-form-group>
</template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "CustomerSearch",
  data(){
    return {
      search_text: '',
      search_result: [],
      search_request: false,
    }
  },
  methods: {
    searchCustomers (value) {
      let self = this;
      if(value.length > 2) {
        Http.request('GET', '/customers?page=1&per_page=15&filter[search]=' + value, null, false).then(function (response) {
          self.search_result = response.data;
        });
      }
    },
    selectCustomer () {
      let self = this;
      let customer = self.search_result.filter(item => item.name === self.search_text);
      if(customer.length > 0){
        Http.request('GET', '/customers/' + customer[0].id + '/educations').then((educations) => {
          self.search_text = '';
          self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + customer[0].id + '/' + educations.data[0].id + '/profile')
        });
      }
    },
  }
}
</script>

<style scoped>
</style>

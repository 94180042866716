import Http from '@/shared/http-config'
import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';

const customerDefault = {
  id: null,
  salutation: 1,
  first_name: '',
  last_name: '',
  birthday: '',
  birth_city: '',
  phone: '',
  mobile: '',
  email: '',
  address: {
    street: '',
    zip: '',
    city: '',
  },
  payment: {
    iban: '',
    bic: '',
    name: '',
    method: {},
    dates: {}
  },
  educations: [],
  branch: {
    id: null
  },
  teacher: {
    id: null
  },
  referral: {
    id: null,
    name: null
  },
  marketing: {
    channel: null,
    event: { id: null },
    feedback: {}
  }
}

export default {
  namespaced: true,

  state () {
    return {
      customer: JSON.parse(JSON.stringify(customerDefault)),
      states: [],
      channels: [],
      events: [],
      education: {
        id: 0,
        tests: {},
        state: {},
        package: {},
        class: {},
        picture: '',
      },
      packages: {},
      educations: [],
      all_educations: [],
      package_variations: {
        1: 'Standard',
        2: 'Power',
        3: 'VIP'
      },
      sort: {
        col: 'activity',
        order: 'desc'
      },
      search: {
        klassen: [],
        category: null,
        schuelerGebM: null,
        schuelerGebY: null,
        massnahmeID: null,
        last_activity: null,
        teamID: null,
        seminarID: null,
        finishTalk: null,
        filialID: null,
        finishTheory: null,
        eKomiFeedback: null,
        feedback: null,
        jmplEventID: null,
        fcCourseID: null,
        mcCourseID: null,
        appointments: null,
        appointments_type: null,
        open_requests: null
      },
    }
  },

  actions: {
    async fetchCustomer({ commit, state }, customer_id = 0){
      return await Http.request(
          'GET',
          '/customers/' + (customer_id > 0 ? customer_id : state.customer.id)
      ).then((response) => {
        let customer = response.data;

        if(customer.birthday === '0000-00-00'){
          delete customer.birthday;
        }
        if(customer.branch === null){
          customer.branch = { id: null };
        }
        if(customer.teacher === null){
          customer.teacher = { id: null };
        }
        if(customer.payment.method === null){
          customer.payment.method = {};
        }
        if(customer.payment.dates?.length > 0){
          customer.payment.dates = customer.payment.dates.filter(item => item);
        }

        commit('set', ['breadcrumbTxt', customer.name]);
        commit('set', ['customer', customer]);
      });
    },
    async storeCustomer({ dispatch, commit, state }, formData = false){
      if(state.customer.birthday === '0000-00-00'){
        delete state.customer.birthday;
      }
      if(state.customer.marketing.event?.id === null){
        delete state.customer.marketing.event;
      }
      if(state.customer.referral?.id === null){
        delete state.customer.referral;
      }

      return await Http.request(
          'POST', '/customers/' + (state.customer.id ?? ''),
          (formData ? formData : state.customer)
        )
        .finally(() => {
          dispatch('fetchCustomer');
        });
    },
    resetCustomer({ commit }){
      commit('set', ['customer', JSON.parse(JSON.stringify(customerDefault))]);
    },
    async fetchStates({ commit, state }, force = false){
      if(state.states.length === 0 || force) {
        return await Http.request('get', '/customers/states').then((states) => {
          commit('set', ['states', states.data]);
        })
      }
      return await new Promise((resolve) => {
        resolve(state.states);
      });
    },
    async fetchEducations(context, customer_id){
      return await Http.request('GET', '/customers/' + customer_id + '/educations').then((response) => {
        let packages = {};
        let educations = [];
        let all_educations = [];
        response.data.forEach(education => {
          if(education.package !== null){
            if (typeof(packages[education.package.id]) === 'undefined') {
              packages[education.package.id] = [];
            }
            packages[education.package.id].push(education);
          }
          else {
            educations.push(education);
          }
          all_educations.push(education);
        });
        context.commit('set', ['packages', packages]);
        context.commit('set', ['educations', educations]);
        context.commit('set', ['all_educations', all_educations]);
      });
    },
    async fetchChannels(context){
      return await Http.request('GET', '/customers/marketing/channels').then((response) => {
        let channels = response.data.map(item => { return { value: item, text: item.title }});
        context.commit('set', ['channels', channels]);
      });
    },
    async fetchEvents(context){
      return await Http.request('GET', '/customers/marketing/events').then((response) => {
        let events = response.data.map(item => { return {
          value: item,
          text: (item.measure ? item.measure.name : '') + ' (' +
            moment(item.start).format('DD.MM.YYYY') +
            (item.start !== item.end ? ' - ' + moment(item.end).format('DD.MM.YYYY') : '') +
          ')'
        }});
        context.commit('set', ['events', events]);
      });
    },
    async saveEducationState(context, { customer_id, education_id, new_status_id }){
      return Http.request('POST', '/customers/' + customer_id + '/educations/' + education_id + '/state/' + new_status_id).then(function (response) {
        context.commit('set', ['education', response.data]);
      });
    },
    resetSearch(context){
      let search = {
        klassen: [],
        category: null,
        schuelerGebM: null,
        schuelerGebY: null,
        massnahmeID: null,
        last_activity: null,
        teamID: null,
        seminarID: null,
        finishTalk: null,
        filialID: null,
        finishTheory: null,
        eKomiFeedback: null,
        feedback: null,
        jmplEventID: null,
        fcCourseID: null,
        mcCourseID: null,
        appointments: null,
        appointments_type: null,
        open_requests: null
      };
      context.commit('set', ['search', search]);
    },
  },

  mutations: {
    updateField,
    set (state, [variable, value]) {
      state[variable] = value
    },
  },

  getters: {
    getField,
    get: (state) => (variable) => {
      return state[variable];
    },
  }
};
